.buttonfx{
  color: black; /* button text color */
  outline: none;
  background: transparent;
  border: none;
  letter-spacing: 0.0625em;
  padding: 12px 30px 12px 30px; 
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: none; /* remove underline if using A instead of BUTTON tag */
  overflow: hidden;
  transition: all .5s;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
}

/* //// Default effect: Slide from Top  //// */

.buttonfx:before,
.buttonfx:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #259f6c; /* onhover background color */
  z-index: -1;
  transform: translate3D(0,-100%,0); /* move elements above button so they don't appear initially */
  transition: all .5s;
}

.buttonfx:before{
  background: #FFC727; /* button default background color */
  z-index: -2;
  transform: translate3D(0,0,0);
}

.buttonfx:hover{
  color: white;
}

.buttonfx:hover:after{
  transform: translate3D(0,0,0);
  transition: all .5s;
}

/* //// Slide Left  //// */

.slideleft:after{
  top: 0;
  z-index: -1;
  transform: translate3D(-101%,0,0);
}

.slideleft:hover:after{
  transform: translate3D(0,0,0);
  transition: all .5s;
}

@media (max-width:850px){
  .buttonfx{
    margin-top: 30px;
  }
}
@media (max-width:550px){
  .buttonfx{
    padding: 10px 25px 10px 25px;
  }
}
