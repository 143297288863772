.category-container {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.category-wrap {
  width: 90%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px 30px 0px;
}
.category-content-wrap {
  width: 35%;
  /* margin-top: 50px; */
}
.category-content-hd {
  font-size: 3.2vw;
  font-weight: 900;
  color: #25234d;
  line-height: 4vw;
  text-transform: capitalize;
}
.category-content-text {
  font-weight: 400;
  color: #25234D;
  font-size: 1.7vw;
  line-height: 2.5vw;
  /* margin-top: 50px; */
}
.category-img-wrap {
  /* position: relative; */
  width: 65%;
  /* top: 50px; */
}
.category-img {
  width: 100%;
  /* padding-top: 100px; */
  /* rotate: -10deg; */
}
@media (max-width: 1024px) {
  .category-wrap{
    min-height: 600px;
  }
  .category-content-wrap {
    margin-top: 0px;
  }
  .category-img-wrap {
    width: 60%;
  }
  .category-content-wrap {
    width: 40%;
  }
  .category-content-hd {
    font-size: 4.2vw;
  }
  .category-content-text {
    font-size: 2vw;
    margin-top: 0px;
  }
}

@media (max-width: 850px) {
  .category-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .category-wrap {
    width: 90%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
  .category-content-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px 0px 50px 0px;
  }
  .category-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }
  .category-img {
    width: 90%;
  }
  .category-content-hd {
    font-size: 5vw;
    line-height: 40px;
  }
  .category-content-text {
    font-size: 2.5vw;
    line-height: 30px;
    margin-top: 0px;
  }
}
@media(max-width:550px){
  .category-wrap{
    justify-content: space-around;
  }
.category-content-hd{
  font-size: 40px;
  line-height: 40px;
}
.category-content-text{
  font-size: 25px;
  line-height: 30px;
}
.category-img-wrap{
  width: 100%;
}
.category-img{
  width: 100%;
}
}
