.footer-wrap {
  width: 100%;
  height: 100%;
  background-color: #ffc727;
  margin-top: 125px;
}
.footer-content-container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-links-wrap {
  /* width: 60%; */
  display: flex;
  gap: 1.8rem;
  justify-content: center;
}
.footer-content-hd {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.68);
  font-weight: 900;
}
.footer-link {
  color: rgba(0, 0, 0, 0.58);
  margin: 0px;
  cursor: pointer;
}
.social-media-all-icon-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.9rem;
  width: 20%;
}
.facebook-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #5c1392;
}
.facebook-icon-wrap:hover {
  background-color: blueviolet;
  cursor: pointer;
}
.facebook-icon {
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 17px;
}
.social-media-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #a496af54;
}
.social-media-icon-wrap:hover {
  background-color: #a496af99;
  cursor: pointer;
}
.social-media-icon {
  font-size: 17px;
  color: white;
  margin: 0px;
  padding: 0px;
}
.copyright-text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.copyright-text {
  color: rgba(0, 0, 0, 0.394);
  margin: 0px;
}

.footer-content-hd-wrap {
  width: 20%;
}

.person-name-arrow-icon-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* height: 100%; */
}
.footer-logo{
  width: 250px;
}
/* media queries */
@media (max-width: 900px) {
  .footer-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-content-hd-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-links-wrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .social-media-all-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
  margin: 10px 0px 20px 0px;

  }
  .social-media-icon-wrap {
    margin-left: 10px;
  }
}

@media (max-width: 550px) {
  .copyright-text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
