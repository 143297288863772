/* header styling */
@font-face {
  font-family: dimbo;
  src: url(../../../font/Dimbo\ Regular.ttf);
}

.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  min-height: 100vh;
  overflow-y: hidden;
  background-image: url(../../../assets/header/header_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* padding-left: 20px; */
}
.left-purpule-donut {
  color: #4e0f89;
  position: absolute;
  left: -250px;
  bottom: -230px;
  width: 450px;
  height: 540px;
  z-index: 80;
  rotate: -10deg;
}
.left-purpule-donut-shadow {
  position: absolute;
  left: -250px;
  bottom: -230px;
  width: 500px;
  height: 570px;
  rotate: -10deg;
}
.white-dottet {
  position: absolute;
  left: 10px;
  bottom: 380px;
  width: 70px;
  height: 180px;
}
.lg-purple-circle {
  position: absolute;
  top: 30px;
  left: 34%;
  width: 170px;
}
.nav-bar-wrap {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16vh;
}

.header-link {
  color: white;
  margin-right: 40px;
  font-size: 1.1vw;
  text-decoration: none;
}
.header-link:hover {
  color: #ffc727;
  cursor: pointer;
}
.header-content-img-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-content-img-container {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
}
.content-hd-wrap {
  z-index: 3;
}
.header-content-wrap {
  z-index: 3;
  width: 45%;
}
.content-hd {
  text-transform: capitalize;
  color: white;
  font-size: 3vw;
  line-height: 4vw;
  margin: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
}
.content-wrap {
  font-weight: 400;
  color: white;
  font-size: 1.5vw;
  line-height: 2.5vw;
  margin-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.header-main-img-container {
  z-index: 1;
  width: 55%;
  padding-bottom: 60px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-main-img {
  width: 100%;
}
.nav-bar-wrap-responsive {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16vh;
}
.header-links-container-res {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -70%;
  height: 100vh;
  width: 40%;
  transition: right 0.3s ease;
  background-color: #4e0f89;
  z-index: 4;
  padding-top: 10px;
}
.header-link-res:hover {
  color: #ffc727;
}

.header-links-container-res.show {
  right: 0;
}
.mobile-icon {
  display: none;
  cursor: pointer;
  font-size: 30px;
  color: white;
}
.close-icon {
  position: absolute;
  top: 34px;
  right: 17px;
  font-size: 20px;
  cursor: pointer;
}
.cross-icon {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.726);
}
.cross-icon:hover {
  color: white;
}
.nav-bar-wrap-responsive {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .nav-bar-wrap-responsive {
    display: flex;
  }
  .nav-bar-wrap {
    display: none;
  }
  .header-links-container-res.show {
    /* box-shadow: -48px 0 452px 0 rebeccapurple; */
    z-index: 6;
    right: 0;
    background-color: #4e0f89;
    border-left: 3px solid #ffc727;
    border-top: 3px solid #ffc727;
  }
  .mobile-icon {
    display: block;
  }
  .header-link-res {
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    text-decoration: none;
  }
  .fiction-genie-hd-res {
    color: white;
    font-size: 25px;
    font-weight: 900;
    margin: 0px;
    margin-top: 20px;
  }
  .nav-bar-links-wrap {
  padding-left: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .header {
    min-height: 600px;
  }
  .header-content-img-container {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .nav-bar-wrap {
    width: 90%;
  }
  .header-link {
    font-size: 1.8vw;
  }
  .fiction-genie-hd {
    font-size: 2vw;
  }
  .header-content-wrap {
    width: 45%;
  }
  .content-hd {
    font-size: 3.8vw;
    line-height: 40px;
  }
  .content-wrap {
    font-size: 2vw;
    line-height: 25px;
  }
  .header-main-img-container {
    width: 55%;
  }
}

@media (max-width: 850px) {
  .header-links-container-res.show {
    width: 50%;
  }
  .header {
    height: max-content;
    min-height: 100vh;
    background-image: none;
    background-color: #4e0f89;
    padding-bottom: 30px;
    padding-left: 0px;
  }

  .nav-bar-main-wrap {
    justify-content: center;
  }
  .nav-bar-wrap {
    height: 15vh;
  }
  .fiction-genie-hd {
    font-size: 3.5vw;
  }
  .header-links-wrap {
    display: none;
  }
  .header-content-img-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .lg-purple-circle {
    display: none;
  }
  .white-dottet {
    display: none;
  }
  .left-purpule-donut-shadow {
    display: none;
  }
  .left-purpule-donut {
    display: none;
  }
  .header-content-wrap {
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }
  .header-main-img-container {
    margin-top: 20px;
    width: 70%;
  }
  .content-hd {
    font-size: 5vw;
    line-height: 6vw;
  }
  .content-wrap {
    font-size: 2.4vw;
    line-height: 3.2vw;
    margin-top: 20px;
  }
}
@media (max-width: 550px) {
  .header-content-img-container {
    justify-content: space-around;
    /* height: 80vh; */
  }
  .fiction-genie-hd {
    font-size: 4.5vw;
  }
  .header-main-img-container {
    padding-top: 30px;
    width: 90%;
  }
  .content-hd {
    font-size: 8vw;
    line-height: 9vw;
  }
  .content-wrap {
    font-size: 5vw;
    line-height: 6vw;
  }
  .header-links-container-res.show {
    width: 80%;
  }

}
@media (max-width: 400px) {
  .content-wrap {
    font-size: 3.8vw;
    line-height: 4.5vw;
  }
}
