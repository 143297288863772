.testimonial-container {
  background-image: url(../../../../assets/testimonal/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  min-height: 100vh;
  position: relative;
}
.testimonial-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-yellow-donut {
  width: 150px;
  position: absolute;
  top: -70px;
  left: -60px;
}

.bg-imgs-and-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.review-people-bg-img {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.white-dotted-bg-img {
  position: absolute;
  width: 60%;
  height: 56vh;
}
.testimonial-hd-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-bottom: 40px;
  margin-top: 20px; */
}
.testimonial-content-hd {
  text-transform: capitalize;
  color: white;
  font-size: 3.8vw;
  font-weight: 900;
  margin: 0px;
  /* line-height: 30px; */
}
.testimonial-card-main-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 60%;
}

.testimonial-card-container {
  min-width: 100%;
  height: 80vh;
  background-image: url(../../../../assets/testimonal/card-bg2.png);
  background-size: 90% 98%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  transition: opacity 0.5s ease-in-out;
}
.testimonial-card-wrap {
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/testimonal/card-bg.png);
  background-size: 95% 95%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
.testimonial-text-container {
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/testimonal/card.png);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  padding-bottom: 10px;
}
.comma-bg-img {
  background-image: url(../../../../assets/testimonal/comma.png);
  background-size: 28% 40%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-text-wrap {
  width: 70%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.testimonial-text {
  /* position: absolute; */
  color: rgba(128, 128, 128, 0.938);
  font-weight: 600;
  margin-top: 100px;
  font-size: 25px;
  transition: transform 1s ease-in-out;
  /* opacity: 0;
    transition: opacity 1s ease-in-out; */
}
.review-person-name {
  color: #6427de;
  font-weight: 900;
  font-size: 20px;
  margin: 0px;
  line-height: 20px;
  text-align: center;
}
.star-icons {
  color: #ffc727;
  margin: 0px;
  font-size: 18px;
  margin-right: 5px;
}
.arrow-icons-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}
.left-arrow-icon-wrap {
  border: 1px solid black;
  outline: none;
  margin-right: 20px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
}
.left-arrow-icon-wrap:hover {
  background-color: #6427de;
  color: white;
  border: none;
}
.left-arrow-icon-wrap:active {
  border: none;
  background-color: #ffc727;
  color: white;
}
.left-arrow-icon-wrap.disabled {
  background-color: rgba(211, 211, 211, 0.616);
  border: 1px solid darkgray;

  color: black;
}
.left-arrow-icon-wrap.disabled:active {
  background-color: rgba(211, 211, 211, 0.616);
  border: 1px solid darkgray;

  color: black;
}
.left-arrow-icon-wrap.disabled:hover {
  background-color: rgba(211, 211, 211, 0.616);
  border: 1px solid darkgray;

  color: black;
}
.right-arrow-icon-wrap {
  border: 1px solid black;
  outline: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
}
.right-arrow-icon-wrap:hover {
  border: none;
  background-color: #6427de;
  color: white;
}
.right-arrow-icon-wrap:active {
  border: none;
  background-color: #ffc727;
  color: white;
}
.right-arrow-icon-wrap.right-disabled {
  background-color: rgba(211, 211, 211, 0.616);
  color: black;
  border: 1px solid darkgray;
}
.right-arrow-icon-wrap.right-disabled:hover {
  background-color: rgba(211, 211, 211, 0.616);
  color: black;
  border: 1px solid darkgray;
}
.right-arrow-icon-wrap.right-disabled:active {
  background-color: rgba(211, 211, 211, 0.616);
  color: black;
  border: 1px solid darkgray;
}
.testimonial-text-wrap.active {
  transform: translateX(0);
}

.testimonial-text-wrap.next {
  transform: translateX(100%);
}

.testimonial-text-wrap.previous {
  transform: translateX(-100%);
}

/* media queries */
@media (max-width: 900px) {
  .testimonial-content-hd {
    font-size: 4.8vw;
  }
  .comma-bg-img {
    background-size: 30% 30%;
  }
}

@media (max-width: 850px) {
  .testimonial-content-hd {
    text-align: center;
    line-height: 40px;
  }
  .review-people-bg-img {
    display: none;
  }
  .testimonial-card-main-container {
    width: 100%;
  }
  .white-dotted-bg-img {
    width: 100%;
  }
  .review-people-bg-img {
    display: none;
  }
}
@media (max-width: 550px) {
  .testimonial-card-container {
    height: 500px;
  }
  .white-dotted-bg-img {
    display: none;
  }
  .white-dotted-bg-img {
    width: 100%;
  }
  .testimonial-text {
    font-size: 4vw;
  }
  .testimonial-content-hd {
    font-size: 6.8vw;
  }
  .comma-bg-img {
    background-size: 28% 30%;
  }
  .testimonial-container {
    min-height: max-content;
  }
}
