.works-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.works-content-wrap {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.works-content-hd {
  text-transform: capitalize;
  font-size: 3.2vw;
  font-weight: 900;
  color: #25234d;
  line-height: 4vw;
  margin: 0px;
}
.works-text {
  font-weight: 400;
  color: #25234D;
  font-size: 1.5vw;
  line-height: 2.5vw;
  text-align: center;
}
.works-img-container {
  /* box-shadow: 0px 61px 75px -5px rgba(0, 0, 0, 0), 0px -48px 279px -5px rgba(0,  0, 0, 0.5); */
  width: 100%;
  box-shadow: 0 -18px 159px rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc727;
  padding: 100px 0px 70px 0px;
  margin-top: 30px;
}
.works-img-wrap {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.story-time-img-container {
  position: relative;
  margin-right: 50px;
  margin-left: 50px;
  width: 20%;
}
.story-time-tablet {
  width: 100%;
  rotate: -10deg;
  border-radius: 20px;
}
.story-time-name-frame {
  position: absolute;
  top: -20%;
  left: 0%;
  z-index: 1;
  width: 70%;
}
.magic-reading-img-container {
  position: relative;
  height: 100%;
  margin-right: 50px;
  width: 20%;
}
.magic-reading-tablet {
  width: 100%;
  rotate: 8deg;
  margin-top: 20px;
  border-radius: 20px;
}
.magic-reading-name-frame {
  position: absolute;
  top: -18%;
  right: 5%;
  z-index: 1;
  width: 70%;
}
.movie-mind-img-container {
  position: relative;
  height: 100%;
  margin-right: 50px;
  width: 20%;
}
.movie-tablet {
  width: 100%;
  rotate: -10deg;
  border-radius: 20px;
}
.movie-name-frame {
  position: absolute;
  top: -20%;
  left: 0%;
  z-index: 1;
  width: 70%;
}

@media (max-width: 1000px) {
  .works-content-hd {
    font-size: 4.5vw;
    line-height: 40px;
  }
  .works-text {
    font-size: 2vw;
    line-height: 25px;
  }
}
@media (max-width: 900px) {
  .movie-mind-img-container {
    width: 200px;
    border-radius: 20px;
    margin-bottom: 70px;
  }
  .magic-reading-img-container {
    width: 200px;
    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 70px;
  }
  .story-time-img-container {
    width: 200px;
    border-radius: 20px;
    margin-bottom: 70px;
  }
  .works-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #ffc727;
    padding: 100px 0px 0px 0px;
  }
  .works-img-wrap {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
}
@media (max-width: 850px) {
  .works-content-wrap {
    text-align: center;
  }
  .works-content-hd {
    font-size: 5vw;
    line-height: 30px;
  }
  .works-text {
    font-size: 2.5vw;
    line-height: 25px;
  }
}
@media (max-width: 550px) {
  .works-content-wrap {
    text-align: center;
  }
  .works-content-hd {
    font-size: 40px;
    line-height: 40px;
  }
  .works-text {
    font-size: 25px;
    line-height: 30px;
  }
}
