.listening-container {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right;
  position: relative;
  padding: 50px 0px 50px 0px;
}
.listening-container::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets//header/header_bg.png);
  background-size: 100% 100%;
  transform: scaleX(-1);
}
.listening-wrap {
  width: 90%;
  height: max-content;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
.listening-img-wrap {
  width: 45%;
}
.listening-girl-img {
  width: 100%;
}
.listening-content-container {
  width: 50%;
}
.listen-hd-wrap {
  color: #ffc727;
  font-size: 3.6vw;
  line-height: 4vw;
  font-weight: 900;
  text-transform: capitalize;
}
.listen-text-wrap {
  font-weight: 400;
  color: white;
  font-size: 1.5vw;
  line-height: 2.5vw;
}

@media (max-width: 1024px) {
  .listening-wrap{
    min-height: max-content;
  }
  .listening-img-wrap {
    width: 45%;
  }
  .listen-hd-wrap {
    font-size: 3.7vw;
  }
  .listen-text-wrap {
    font-size: 1.7vw;
  }
}
@media (max-width: 850px) {
  .listening-container {
    background-color: #4e0f89;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .listening-container::before {
    display: none;
  }
  .listening-wrap {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    z-index: 2;
  }
  .listening-content-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 30px 0px 50px 0px;
  }
  .listening-img-wrap {
    width: 80%;
  }
  .listen-hd-wrap {
    color: #ffc727;
    font-size: 5vw;
    line-height: 45px;
  }
  .listen-text-wrap {
    font-size: 2.4vw;
    line-height: 30px;
  }
}
@media (max-width: 550px) {
  .listen-hd-wrap {
    font-size: 35px;
  }
  .listen-text-wrap {
    font-size: 25px;
  }
  .listening-img-wrap{
    width: 100%;
  }
}
