.guiding-parents-main-container {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guiding-parents-container {
  width: 90%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.guiding-parent-content-wrap {
  width: 50%;
}
.guiding-parent-hd {
  text-transform: capitalize;
  color: #25234d;
  font-weight: 900;
  font-size: 3.2vw;
  line-height: 4vw;
}
.guiding-parent-text {
  font-weight: 400;
  color: #25234D;
  font-size: 1.5vw;
  line-height: 2.5vw;
}
.guiding-parent-img-wrap {
  background-image: url(../../../assets/parent-guide/Circle_.png);
  background-repeat: no-repeat;
  background-size: 60% 50%;
  background-position: right top;
  width: 50%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  /* margin-bottom: 20px; */
}
.guiding-parent-img {
  width: 100%;
  margin-top: 10vw;
  position: relative;
  top: 40px;
}

@media (max-width: 1024px) {
  .guiding-parents-container{
    min-height: 600px;
  }
  .guiding-parent-hd {
    font-size: 3.7vw;
  }
  .guiding-parent-text {
    font-size: 1.7vw;
  }
}
@media (max-width: 850px) {
  .guiding-parents-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .guiding-parents-container {
    width: 95%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .guiding-parent-content-wrap {
    width: 100%;
    text-align: center;
    margin: 30px 0px 50px 0px;
  }
  .guiding-parent-img-wrap {
    width: 80%;
  }
  .guiding-parent-img{
    margin-top: 15vw;
    top: 0px;
  }
  .guiding-parent-hd {
    font-size: 5vw;
    line-height: 40px;
  }
  .guiding-parent-text {
    font-size: 2.4vw;
    line-height: 30px;
  }
}
@media (max-width:650px){
  .guiding-parent-img{
    margin-top: 60px;
  }
}
@media (max-width: 550px) {
  .guiding-parent-img-wrap {
    width: 90%;
  }
  .guiding-parent-hd {
    font-size: 30px;
    line-height: 40px;
  }
  .guiding-parent-text {
    font-size: 25px;
    line-height: 40px;
  }
}
