/* crousel css */

.carousel-main-container {
  background-image: url(../../../assets/what\ reader/White_BG.png);
  height: max-content;
  min-height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  box-shadow: 0px 48px 100px -5px rgba(0, 0, 0, 0.5),
    0px -14px 44px -5px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}
.carousel-hd-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.carosuel-hd {
  text-transform: capitalize;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.5vw;
  color: #1b3e5c;
  font-weight: 900;
  text-align: center;
  padding: 0px 10px 0px 10px;
}
.categories-name-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.category-name {
  font-size: 2vw;
  padding: 1.5vw;
  border: none;
  outline: none;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.855);
  font-weight: 600;
  margin-bottom: 10px;
  transition: background-color 0.5s, border-radius 0.3s, box-shadow 0.3s;
}
.category-name:hover {
  background-color: #ffc727;
  border-radius: 10px;
  cursor: pointer;
}
.category-name:focus,
.category-name.selected {
  background-color: #ffc727;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.see-category-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.courousel-main-wrap {
  position: relative;
  width: 75% !important;
  margin: 0px auto 0px auto;
  padding-top: 100px;
  padding-bottom: 20px;
}
.category-book-title {
  text-align: center;
  font-weight: 900;
  color: #000;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0px;
}
.category-arrow-wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffc727;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* position: absolute;
  top: -50px;
  right: 0px; */
}
.category-arrow {
  font-size: 25px;
  color: #5a1291;
}
.category-right-arrow {
  position: absolute;
  top: -50px;
  right: 0px;
}
.category-left-arrow {
  position: absolute;
  top: -50px;
  right: 70px;
}
.disable {
  background-color: #ffc52775;
}
.scroll-container {
  position: relative;
  display: flex;
  align-items: center;
}
.all-cards {
  display: flex;
  gap: 30px;
  overflow: hidden;
  padding: 20px;
  padding-left: 18px;
  width: 100%;
  transition: transform 0.3s ease-out;
}
.all-cards::-webkit-scrollbar {
  display: none;
}
.all-cards.stretch {
  transform: scale(1.05);
}
.card-container {
  max-width: 230px;
  min-width: 230px;
  position: relative;
}
.card-container:hover {
  opacity: 1;
  transition: transform scale(1.05) opacity 1s;
  cursor: pointer;
}
.book-cover {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  box-shadow: -3px 4px 15px rgba(0, 0, 0, 0.849);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  /* transition: .5s ease;
  backface-visibility: hidden; */
}
.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.book-spine {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  width: 12px;
  background: linear-gradient(to left, transparent, #000 80%);
  box-shadow: 0 10px rgba(0, 0, 0, 0.5);
}
.loader {
  color: white;
  font-size: 30px;
}

/* .book-cover:hover:after {
  background : rgba( 0, 0, 0, 0.6 );
  bottom : 0;
  content : ' ';
  left : 0;
  position : absolute;
  right : 0;
  top : 0;
} */

/* .book-cover:hover .card-img {
  background-color: #00000098;
  opacity: 0.5;
 backdrop-filter: black;
} */
.card-hover-wrap {
  position: absolute;
  top: 0px;
  width: 230px;
  height: 300px;

}
.card-hover-animation{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.card-hover-wrap:hover .card-hover-animation {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.404);
}
.center {
  width: 180px;
  height: 60px;
  position: absolute;
}

.button {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  outline: none;
  transition: 1s ease-in-out;
  border-radius: 10px;
  border: none;
}

.button svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: white;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.button:hover {
  transition: 1s ease-in-out;
  background: rgba(0, 0, 0, 0.534);
}

.button:hover svg {
  stroke-dashoffset: -480;
}

.button span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}
@media (max-width: 1025px) {
  .carousel-main-container {
    min-height: 600px;
  }
  .carosuel-hd {
    font-size: 3.7vw;
  }
  .category-name {
    font-size: 1.7vw;
  }
}

@media (max-width: 850px) {
  .carousel-main-container {
    margin-top: 0px;
    min-height: 100vh;
    padding-bottom: 40px;
    padding-top: 10px;
  }
  .carosuel-hd {
    font-size: 5vw;
  }
  .category-name {
    font-size: 2.4vw;
  }
}
@media (max-width: 550px) {
  .carosuel-hd {
    font-size: 6vw;
  }
  .category-name {
    font-size: 4vw;
  }
}
@media (max-width: 500px) {
  .all-cards {
    overflow-x: auto;
  }
  .category-left-arrow {
    display: none;
  }
  .category-right-arrow {
    display: none;
  }
  .courousel-main-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
